import React, { useState, useEffect, useRef } from 'react';
import './MatchupContainer.css';
import { Toggle, WinPercent } from './Toggle';
import { MatchupPicks } from './MatchupPicks';

import { Gamecast } from '../Gamecast/Gamecast';

const MatchupContainer = ({ defaultVal, preseason, matchup, handleTeamClick, selectedTeamIds, teams, onPageChange, type, cv, change }) => {

    const [HomeScore, setHomeScore] = useState(matchup.competitions[0].competitors[0].score);
    const [AwayScore, setAwayScore] = useState(matchup.competitions[0].competitors[1].score);

    

    const [isToggleVisible, setToggleVisible] = useState(false);

    const toggleDetails = () => {
        setToggleVisible(!isToggleVisible);
    };

    const homeTeam = matchup.competitions[0]?.competitors[0]?.team || 'Team Not Available';
    const awayTeam = matchup.competitions[0]?.competitors[1]?.team || 'Team Not Available';
    const date = new Date(matchup.competitions[0].date);

    const homeRecord = matchup.competitions[0].competitors[0].records ? matchup.competitions[0].competitors[0].records[0].summary : "0-0";
    const homehomeRecord = matchup.competitions[0].competitors[0].records ? matchup.competitions[0].competitors[0].records[1].summary : "0-0";
    const awayRecord = matchup.competitions[0].competitors[1].records ? matchup.competitions[0].competitors[1].records[0].summary : "0-0";
    const awayroadRecord = matchup.competitions[0].competitors[1].records ? matchup.competitions[0].competitors[1].records[2].summary : "0-0";


    const formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        //year: 'none',
        timeZone: 'America/New_York',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'America/New_York',
    });

    const city = matchup.competitions[0]?.venue?.address?.city;
    const state = matchup.competitions[0]?.venue?.address?.state;

    const homeColor = homeTeam.color || '000';
    const awayColor = awayTeam.color || '000';

    // Check if the team is selected based on its ID and matchup ID
    var isHomeTeamSelected = false;
    var isAwayTeamSelected = false;
    if (selectedTeamIds && selectedTeamIds[matchup.id] && selectedTeamIds[matchup.id].pick) {
        isHomeTeamSelected = homeTeam && selectedTeamIds[matchup.id].pick === homeTeam.name;
        isAwayTeamSelected = awayTeam && selectedTeamIds[matchup.id].pick === awayTeam.name;
    }

    var week = matchup.week.number;
    if (matchup.season.type == 1) {
        week = 1;
    }
   

    var multiplier = 1;
    if (matchup.season.type == 3) {
        multiplier = 2*matchup.week.number;
    }

    if(type=="ncaa"){
        week = 1;
    }

    if (teams[homeTeam.name] && teams[awayTeam.name] && teams[homeTeam.name].rating[week] && teams[awayTeam.name].rating[week]) {
        var homeVal = teams[homeTeam.name].rating[week] * 1;
        homeVal += 0.5;

        var AwayVal = teams[awayTeam.name].rating[week] * 1;
        AwayVal -= 0.5;

        var homeValUnrounded = homeVal - AwayVal;

        homeVal = homeVal - AwayVal;
        //homeVal *= multiplier;
        homeValUnrounded = 10 * multiplier - Math.max(0.1, Math.min(9.9 * multiplier, (multiplier * homeVal / 5 + multiplier * 5)));
        homeVal = Math.round(10 * multiplier - Math.max(1, Math.min(9 * multiplier, (multiplier * homeVal / 5 + multiplier * 5))));

    }
    else {
        //homeValUnrounded = 5;
        //homeVal = Math.round(homeValUnrounded * multiplier);
        homeVal = "?";
    }
    //homeVal *= multiplier;


    var ip = 0;
    var igo = 0;
    var winner = [];
    var correct = 0;
    var w = "";

    var place = city;
    if (state) {
        place = `${city}, ${state}`;
    }
    if (matchup.competitions[0].notes[0]?.headline) {
        place = matchup.competitions[0].notes[0]?.headline;
    }

    if (matchup.status?.type?.description !== "Scheduled") {
        ip = 1;
    }
    
    if (new Date() > date) {
        ip = 1;
    }

    if (matchup.status?.type?.completed) {
        //setGameIsOver(1);
        var igo = 1;
        winner["home"] = matchup.competitions[0]?.competitors[0]?.winner;
        winner["away"] = matchup.competitions[0]?.competitors[1]?.winner;
        correct = -1;
        if (winner["home"] && isHomeTeamSelected) {
            correct = 1;

        }
        else if (winner["away"] && isAwayTeamSelected) {
            correct = 1;
        }

        if (winner["home"]) {
            w = "home";
        }
        else if (winner["away"]){
            w = "away";
        }
    }


    const quarters = {
        "0":"",
        "1": "st",
        "2": "nd",
        "3": "rd",
        "4": "th"
    };

    const [inProgress, setInProgress] = useState(ip);
    const [GameIsOver, setGameIsOver] = useState(igo);

    useEffect(() => {
        const gameStatus = async () => {
            const response = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/events/${matchup.id}/competitions/${matchup.id}/status?lang=en&region=us`);
            const status = await response.json();

            if (status?.type?.description !== "Scheduled" || new Date() > date) {
                setInProgress(1);
            }
            if (status?.type?.completed) {
                setGameIsOver(1);
            }
            
        }

        const intervalId = setInterval(gameStatus, Math.floor(Math.random() * 60000) + 60000);

        return () => clearInterval(intervalId);

    }, [matchup, date]);

    if (inProgress && GameIsOver) {
        //setGameIsOver(0);
    }

    const [time, setTime] = useState(null);
    const [down, setDown] = useState(null);

    const [thisPlay, setThisPlay] = useState(null);
    const [playBefore, setPlayBefore] = useState(null);
    const [thisDrive, setThisDrive] = useState(null); 

    const [possession, setPossession] = useState(null); 

    const [fieldTiemLogo, setFieldTeamLogo] = useState(null); 

   const [driveIndex, setDriveIndex] = useState(0);
    const [playIndex, setPlayIndex] = useState(0);

    const [HomeOdds, setHomeOdds] = useState(null);


    useEffect(() => {
    const fetchDataAsync = async () => {
        if (inProgress && !GameIsOver && type != "ncaa") {
            try {
                const response = await fetch(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/events/${matchup.id}/competitions/${matchup.id}/drives?limit=100`);
                
                const lastPlay = await response.json();
                const items = lastPlay.items;

                //DRIVE
                const DRIVE = items[items.length - 1];
                setThisDrive(DRIVE);

                // PLAY
                const playsItems = DRIVE.plays.items;
                const PLAY = playsItems[playsItems.length - 1];
                const PLAYBEFORE = playsItems[playsItems.length - 2];

                setPlayBefore(PLAYBEFORE);
                setThisPlay(PLAY);

                // ODDS
                var purl = PLAY.probability.$ref;
                 if (purl.startsWith('http://')) {
                    purl = purl.replace('http://', 'https://');
                }
                const ODDSresponse = await fetch(purl);
                const ODDS = await ODDSresponse.json();

                setHomeOdds(ODDS.homeWinPercentage);

                // PLAY INFO
                setTime((PLAY.clock.displayValue == "0:00" ? "End of " : PLAY.clock.displayValue + " - ") + PLAY.period.number + quarters[PLAY.period.number]);
                setDown(PLAY.end.downDistanceText);
                if (PLAY.text == "END GAME") {
                    setTime("FINAL");
                    setDown(null);
                    setGameIsOver(1);
                }
                

                // CURRENT SCORE
                setHomeScore(PLAY.homeScore);
                setAwayScore(PLAY.awayScore);

                // POSSESSION
                const url = PLAY.team.$ref;
                const segments = url.split('/');
                const teamId = segments[segments.length - 1].split('?')[0];

                if(teamId == homeTeam.id){
                    setPossession("HOME-POSSESSION");
                    //setTeamPossession(homeTeam.abbreviation);
                    setFieldTeamLogo(homeTeam.logo);
                }
                else{
                    setPossession("AWAY-POSSESSION");
                    //setTeamPossession(awayTeam.abbreviation);
                    setFieldTeamLogo(awayTeam.logo);
                }

                // NEXT PLAY
                setPlayIndex((prevPlayIndex) => {
                    const nextPlayIndex = (prevPlayIndex + 1) % playsItems.length;
                    if (nextPlayIndex === 0) {
                        setDriveIndex((prevDriveIndex) => (prevDriveIndex + 1) % items.length);
                    }
                    return nextPlayIndex;
                });

            } catch (error) {
                //console.error('Error fetching drives:', error);
            }
        }
    };
    
    if(driveIndex == 0 && playIndex == 0){
        fetchDataAsync();
    }
    const intervalId = setInterval(fetchDataAsync, Math.floor(Math.random() * 0) + 2500);

    return () => clearInterval(intervalId);
}, [matchup.id, driveIndex, playIndex]); // Add dependencies if needed


    const homeTeamNameNoSpaces = (homeTeam != undefined && homeTeam.name != undefined ? homeTeam.name.replace(/\s+/g, '_') : "TBD");
    const homecss = `
    #${`teamid-${homeTeamNameNoSpaces}`}.selected .teamLogo{
        box-shadow: 0 0 0 3px #${homeColor} inset;
        border: 2px solid white;
    }
    `;
    const awayTeamNameNoSpaces = (awayTeam != undefined && awayTeam.name != undefined ? awayTeam.name.replace(/\s+/g, '_') : "TBD");
    const awaycss = `
   #${`teamid-${awayTeamNameNoSpaces}`}.selected .teamLogo{
        box-shadow: 0 0 0 3px #${awayColor} inset;
        border: 2px solid white;
    }
    `;

    var odds = null;
    var ou = null;
    var homeWin = null;

    if (matchup.competitions[0].odds != null) {
        homeWin = matchup.competitions[0].odds[0].homeTeamOdds.favorite;
        odds = matchup.competitions[0].odds[0].spread;
        ou = matchup.competitions[0].odds[0].overUnder;
    }

    return (
        <div className={`${inProgress ? 'inProgress' : ''} ${correct === 1 ? 'correct' : GameIsOver ? "wrong" : ""} matchup-container ${isHomeTeamSelected || isAwayTeamSelected ? 'marked' : ''}`}>
            <div className="date">
                <h4>{formattedDate}</h4>
                <h5>
                    {!GameIsOver ? (
                        <span>{formattedTime == "12:00 AM" ? "" : `${formattedTime} EST - `}{place}</span>
                    ) : (
                        <span>{matchup.status.type.shortDetail}</span>
                    )}                </h5>
            </div>
            <div className="teams">
                <div
                    id={homeTeam.name == "TBD" ? `teamid-home-${matchup.id}` : `teamid-${homeTeamNameNoSpaces}`}
                    className={`home team ${isHomeTeamSelected ? 'selected' : ''}`}
                    onClick={!inProgress ? () => handleTeamClick(homeTeam.name, matchup.id, homeVal * 1, date) : null}
                >
                    <div className={homeTeam.logo ? 'teamLogo' : 'teamLogo TBD'}><img src={homeTeam.logo || 'redg.png'} alt={homeTeam.name} /></div>
                    {type !== "ncaa" ? <div className="circle">{homeVal * 1}</div> : null}
                    <p>{type == "ncaa" ? homeTeam.shortDisplayName : homeTeam.name}<br></br>{homeRecord}</p>
                    <style>
                        {homecss}
                    </style>
                </div>
                {!GameIsOver && type!="ncaa" ? (
                    <div className="box-score">
                        <div className="home-score">
                            {HomeScore} {possession=="HOME-POSSESSION" && (<div className="possession"><img src='football.png' /></div>)}
                        </div>
                       
                        <div className="game-status">
                            <div className="game-status-time">
                                {time}
                            </div>
                            <div className="game-status-down">
                                {down === "" ? <br /> : down}
                            </div>

                            
                        </div>
                        <div className="away-score">
                            {AwayScore} {possession=="AWAY-POSSESSION" && (<div className="possession"><img src='football.png' /></div>)}
                        </div>
                    </div>
                ) : (
                    <div className="box-score">
                        <div className="home-score">
                            {HomeScore}
                        </div>
                            <div className="game-status">
                                {preseason || (type=="ncaa" && !GameIsOver) ? "-" : (
                                    <div className={`${correct === 1 ? 'correctGuess' : 'wrongGuess'}`}>
                                        <img src={`${correct === 1 ? 'check.png' : 'ex.png'}`} />
                                    </div>)}
                        </div>
                        <div className="away-score">
                            {AwayScore}
                        </div>
                    </div>
                )}

                <div
                    id={awayTeam.name == "TBD" ? `teamid-away-${matchup.id}` : `teamid-${awayTeamNameNoSpaces}`}
                    className={`away team ${isAwayTeamSelected ? 'selected' : ''}`}
                    onClick={!inProgress ? () => handleTeamClick(awayTeam.name, matchup.id, isNaN(10 - homeVal) ? '?' : 10 - homeVal, date) : null}
                >
                    <div className={awayTeam.logo ? 'teamLogo' : 'teamLogo TBD'}><img src={awayTeam.logo || 'redg.png'} alt={awayTeam.name} /></div>
                    {type !== "ncaa" ? <div className="circle">{isNaN(10 - homeVal) ? '?' : (10 * multiplier - homeVal) * 1}</div> : null}
                    <p>{type == "ncaa" ? awayTeam.shortDisplayName : awayTeam.name}<br></br>{awayRecord}</p>
                    <style>
                        {awaycss}
                    </style>
                </div>
            </div>
            
            {type=="ncaa" ?  (<Certainty  defaultVal={defaultVal} hc={homeColor} ac={awayColor} cv={cv} change={change} id={matchup.id} home={homeTeam.name} away={awayTeam.name} inProgress={inProgress} status={correct === 1 ? 'correct' : GameIsOver ? "wrong" : ""} />) : null}

            {isToggleVisible && <ToggleContent multiplier={multiplier}  thisDrive={thisDrive} thisPlay={thisPlay} logo={fieldTiemLogo} possession={possession} GameIsOver={GameIsOver} odds={odds} ou={ou} homeWin={homeWin} league={type} winner={w} inProgress={inProgress} teams={matchup} homeVal={homeValUnrounded} hc={homeColor} ac={awayColor} onPageChange={onPageChange} playBefore={playBefore} liveOdds={HomeOdds} />}
            {isToggleVisible  && <div className="details" onClick={toggleDetails}>Details <img src="up.png" alt="up"  /></div>}
            {!isToggleVisible && type!="ncaa" && <div className="details" onClick={toggleDetails}>Details <img className="open" src="up.png" alt="down" /></div>}
           
        </div>
    );
};

export default MatchupContainer;

const Certainty = ({defaultVal, hc, ac, cv, change, id, home, away, inProgress, status}) => {

  const [value, setValue] = useState(cv);
  const sliderRef = useRef(null);
  const [HColor, sethc] = useState(hc);
  const [AColor, setac] = useState(ac);
  const [d] = useState(defaultVal);
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (sliderRef.current) {
      const percent = (value / 100) * 100;
      if(value > 50){
        change(value, id, home);
        if(status == "wrong"){
            sethc("999");
        }
      }
      else if(value < 50){
          change(100 - value, id, away);
          if(status == "wrong"){
            setac("999");
        }
      }
      else{
          change(50, id, "tie");
      }
      
      sliderRef.current.style.background = `linear-gradient(to right, #${HColor} ${percent}%, #${AColor} ${percent}%)`;
    }
  }, [value]);
    
  return (
    <div className={`sliderHolder${inProgress ? " nv" : ""}`}>
    <h4>Drag Slider to Make Prediction</h4>
    <style>
        {`
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width:20px;
            height:20px;
            background: #${value > 50 ? HColor : value < 50 ? AColor : "000"}; /* Color of the knob */
            cursor: pointer;
            border-radius: 50px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            padding-bottom:20px;
          }

          input[type="range"]::-moz-range-thumb {
            background: #2196F3; /* Color of the knob */
          }

          input[type="range"]::-ms-thumb {

            background: #2196F3; /* Color of the knob */

          }
        `}
      </style>
      <input
        ref={sliderRef}
        type="range"
        min="0"
        max="100"
        value={value}
        onChange={handleChange}
        style={{
          appearance: 'none',
          width: '100%',
          height: '8px',
          borderRadius: '5px',
          background: `linear-gradient(to right, #${HColor} ${value}%, #${AColor} ${value}%)`,
          outline: 'none',
          padding: 0,
          margin: 0,
        }}
      />
      <div className="floater"
        style={{
          left: `calc(${value*300/300}%)`,  // Center the label horizontally on the thumb
          backgroundColor:  '#'+(value > 50 ? HColor : value < 50 ? AColor : "000"),  // Same color as the thumb
        }}
      >
        {value != 50 ? 50+Math.abs(50-value) + "%" : "TIE"}
      </div>
      {d && <div className="description">Default Projection Generated by GIBBS Power Ratings</div>}
    </div>
  );
}




const ToggleContent = ({ odds, homeWin, league, winner, teams, homeVal, hc, ac, inProgress, onPageChange, liveOdds, GameIsOver, thisDrive, thisPlay, logo, possession, playBefore, ou, multiplier }) => {

    const match = teams.competitions[0];
    const homeid = match.competitors[0].id;
    const awayid = match.competitors[1].id;
    const gameid = match.id;

    // Define state to track if data retrieval failed
    const [dataFailed, setDataFailed] = useState(false);

    const footerClick = (page) => {
        onPageChange(page); // Update the current page in the App component
    };

    // Render Player Stats section if data retrieval succeeds
    return (
        <div className="toggle-content">
            {/*{inProgress && odds == null ? (
                <div className={`wpw winner-${winner}`}>
                    
                    <WinPercent odds={liveOdds} inProgress={inProgress} homeWin={homeWin} val={homeVal} hc={hc} ac={ac} GameIsOver={GameIsOver} />
                    
                </div>
            ) : (
                    <>
                        {inProgress == 1 && <Gamecast thisDrive={thisDrive} thisPlay={thisPlay} logo={logo} possession={possession} playBefore={playBefore} hc={hc} ac={ac} />}
                    <div onClick={() => footerClick(`${league}-teams`)}>
                       
                        <WinPercent odds={odds} inProgress={inProgress} homeWin={homeWin} val={homeVal} hc={hc} ac={ac} GameIsOver={GameIsOver} />
                        </div>
                    </>
            )}*/}

            {
                (inProgress == 1 ?
                    (GameIsOver == 1 ?
                        <MatchupPicks matchup={teams} week={teams.week.number} /> :
                        <>
                            {/*<Gamecast thisDrive={thisDrive} thisPlay={thisPlay} logo={logo} possession={possession} playBefore={playBefore} hc={hc} ac={ac} />
                            */
                                <div>
                                    <WinPercent liveOdds={liveOdds} inProgress={1} homeWin={homeWin} val={homeVal} hc={hc} ac={ac} GameIsOver={GameIsOver} multiplier={multiplier} />
                            </div>}
                            <MatchupPicks matchup={teams} week={teams.week.number} />
                            
                        </>
                    ) :
                    <div>
                        <WinPercent inProgress={inProgress} homeWin={homeWin} odds={odds} val={homeVal} hc={hc} ac={ac} GameIsOver={GameIsOver} ou={ou} multiplier={multiplier} />
                    </div>
                )
            }

            {/*league == "nfl" && GameIsOver ? (
                <>
            <h2>Player Stats</h2>
            <div className="stats-div">
                <Toggle type="home-stats col" id={homeid} game={gameid} teams={teams} />
                <div className="middle col">
                    <div className="passing"><span>passing yards</span></div>
                    <div className="rushing"><span>rushing yards</span></div>
                    <div className="receiving"><span>receiving yards</span></div>
                </div>
                <Toggle type="away-stats col" id={awayid} game={gameid} teams={teams} />
            </div>
            </>
            ) : null*/}
        </div>
    );
};
